var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _vm.$route.params.attachmentId == undefined
        ? _c(
            "div",
            { staticClass: "search-toolbar-wrapper" },
            [_c("search-toolbar", { on: { search: _vm.search } })],
            1
          )
        : _vm._e(),
      _c(
        "ejs-grid",
        {
          ref: "attachments-logs-grid",
          attrs: {
            dataSource: _vm.data,
            allowPaging: true,
            allowFiltering: true,
            allowSorting: true,
            sortSettings: _vm.sortOptions,
            filterSettings: _vm.filterOptions,
            toolbar: _vm.toolbarOptions,
            searchSettings: _vm.searchOptions,
            pageSettings: _vm.pageSettings,
            editSettings: _vm.editSettings
          },
          on: { actionFailure: _vm.actionFailure }
        },
        [
          _c(
            "e-columns",
            [
              _c("e-column", {
                attrs: {
                  field: "Id",
                  headerText: _vm.$t("shared.id"),
                  isPrimaryKey: "true",
                  width: "0",
                  visible: false
                }
              }),
              _c("e-column", {
                attrs: { field: "OwnerType", width: "0", visible: false }
              }),
              _c("e-column", {
                attrs: {
                  field: "OwnerTypeName",
                  headerText: _vm.$t("attachmentTypes.owner"),
                  width: "100",
                  filter: _vm.textBoxFilter,
                  visible: _vm.$route.params.attachmentId == undefined
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "OwnerName",
                  headerText: _vm.$t("attachments.owner"),
                  width: "120",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: { field: "AttachmentTypeId", width: "0", visible: false }
              }),
              _c("e-column", {
                attrs: {
                  field: "AttachmentTypeName",
                  headerText: _vm.$t("attachments.type"),
                  width: "120",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "Name",
                  headerText: _vm.$t("attachments.name"),
                  width: "200",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: { field: "Action", width: "0", visible: false }
              }),
              _c("e-column", {
                attrs: {
                  field: "ActionName",
                  headerText: _vm.$t("shared.action"),
                  width: "80",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: { field: "UserId", width: "0", visible: false }
              }),
              _c("e-column", {
                attrs: {
                  field: "UserName",
                  headerText: _vm.$t("shared.userName"),
                  width: "120",
                  filter: _vm.textBoxFilter
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "LogDateTime",
                  headerText: _vm.$t("shared.dateTime"),
                  width: "90",
                  format: "dd/MM/y HH:mm"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }